export const feelingsData = {
  //primary
  Positive: {
   'Worthy/Confident': [
    { name: 'Accepted' },
    { name: 'Admired' },
    { name: 'Assertive' },
    { name: 'Bold' },
    { name: 'Brave' },
    { name: 'Capable' },
    { name: 'Clever' },
    { name: 'Confident' },
    { name: 'Courageous' },
    { name: 'Desired' },
    { name: 'Determined' },
    { name: 'Driven' },
    { name: 'Empowered' },
    { name: 'Energised' },
    { name: 'Fascinating' },
    { name: 'Important' },
    { name: 'Inspirational' },
    { name: 'Intelligent' },
    { name: 'Interesting' },
    { name: 'Proud' },
    { name: 'Sexy' },
    { name: 'Stimulated' },
    { name: 'Strong' },
    { name: 'Understood' },
    { name: 'Vibrant' },
    { name: 'Worthy' },
  ],

  'Love/Safety': [
    { name: 'Admiration' },
    { name: 'Adored' },
    { name: 'Affection' },
    { name: 'Affectionate' },
    { name: 'Attraction' },
    { name: 'Attractive' },
    { name: 'Blessed' },
    { name: 'Caring' },
    { name: 'Comforted' },
    { name: 'Compassion' },
    { name: 'Connected' },
    { name: 'Devoted' },
    { name: 'Devotion' },
    { name: 'Empathy' },
    { name: 'Fondness' },
    { name: 'Infatuated' },
    { name: 'Infatuation' },
    { name: 'Inspired' },
    { name: 'Kindness' },
    { name: 'Love' },
    { name: 'Loved' },
    { name: 'Loving' },
    { name: 'Passionate' },
    { name: 'Reassured' },
    { name: 'Safe' },
    { name: 'Secure' },
    { name: 'Sentimental' },
    { name: 'Sympathetic' },
    { name: 'Tender' },
    { name: 'Tenderness' },
    { name: 'Trusted' },
    { name: 'Trusting' },
  ],

  'Happy/Grateful': [
    { name: 'Appreciated' },
    { name: 'Appreciative' },
    { name: 'Delighted' },
    { name: 'Ecstatic' },
    { name: 'Elated' },
    { name: 'Enchanted' },
    { name: 'Encouraged' },
    { name: 'Enjoyment' },
    { name: 'Euphoric' },
    { name: 'Excited' },
    { name: 'Fortunate' },
    { name: 'Grateful' },
    { name: 'Great' },
    { name: 'Happy' },
    { name: 'High' },
    { name: 'Invigorated' },
    { name: 'Joy' },
    { name: 'Lucky' },
    { name: 'Moved' },
    { name: 'Optimistic' },
    { name: 'Overjoyed' },
    { name: 'Pleased' },
    { name: 'Positive' },
    { name: 'Radiant' },
    { name: 'Refreshed' },
    { name: 'Rejuvenated' },
    { name: 'Superb' },
    { name: 'Terrific' },
    { name: 'Thankful' },
    { name: 'Thrilled' },
    { name: 'Touched' },
    { name: 'Wonderful' },
  ],

  'Open/Playful': [
    { name: 'Adventurous' },
    { name: 'Amazed' },
    { name: 'Amused' },
    { name: 'Animated' },
    { name: 'Anticipation' },
    { name: 'Carefree' },
    { name: 'Challenged' },
    { name: 'Cheeky' },
    { name: 'Cheerful' },
    { name: 'Considerate' },
    { name: 'Curious' },
    { name: 'Daring' },
    { name: 'Enthusiastic' },
    { name: 'Fascinated' },
    { name: 'Free' },
    { name: 'Friendliness' },
    { name: 'Impulsive' },
    { name: 'Inquisitive' },
    { name: 'Interested' },
    { name: 'Intrigued' },
    { name: 'Keen' },
    { name: 'Liberated' },
    { name: 'Lively' },
    { name: 'Mystified' },
    { name: 'Open' },
    { name: 'Playful' },
    { name: 'Rebellious' },
    { name: 'Receptive' },
    { name: 'Surprised' },
    { name: 'Warm' },
  ],

  'Calm/Focused': [
    { name: 'Absorbed' },
    { name: 'Accepting' },
    { name: 'Attentive' },
    { name: 'Blissful' },
    { name: 'Calm' },
    { name: 'Centered' },
    { name: 'Comfortable' },
    { name: 'Content' },
    { name: 'Engaged' },
    { name: 'Engrossed' },
    { name: 'Focused' },
    { name: 'Fulfilled' },
    { name: 'Grounded' },
    { name: 'Humble' },
    { name: 'Humility' },
    { name: 'Mellow' },
    { name: 'Modesty' },
    { name: 'Nostalgic' },
    { name: 'Patient' },
    { name: 'Peaceful' },
    { name: 'Pensive' },
    { name: 'Present' },
    { name: 'Reflective' },
    { name: 'Relaxed' },
    { name: 'Relieved' },
    { name: 'Satisfied' },
  ],
    
  },

  
  Negative: {
    'Angry/Frustrated': [
    { name: 'Aggressive' },
    { name: 'Angry' },
    { name: 'Annoyed' },
    { name: 'Bitter' },
    { name: 'Cross' },
    { name: 'Cruel' },
    { name: 'Enraged' },
    { name: 'Frustrated' },
    { name: 'Furious' },
    { name: 'Hate' },
    { name: 'Irritated' },
    { name: 'Mad' },
    { name: 'Nasty' },
    { name: 'Resentful' },
    { name: 'Ruthless' },
    { name: 'Vengeful' },
    { name: 'Vicious' },
    { name: 'Wronged' },
  ],

  'Confused/Overwhelmed': [
    { name: 'Adrift' },
    { name: 'Baffled' },
    { name: 'Bewildered' },
    { name: 'Bonkers' },
    { name: 'Crazy' },
    { name: 'Dazed' },
    { name: 'Disoriented' },
    { name: 'Disturbed' },
    { name: 'Foggy' },
    { name: 'Horrified' },
    { name: 'Hysterical' },
    { name: 'Impulsive' },
    { name: 'Loopy' },
    { name: 'Messed up' },
    { name: 'Mixed up' },
    { name: 'Moody' },
    { name: 'Mortified' },
    { name: 'Nuts' },
    { name: 'Out of control' },
    { name: 'Overwhelmed' },
    { name: 'Perplexed' },
    { name: 'Puzzled' },
    { name: 'Restlessness' },
    { name: 'Shocked' },
    { name: 'Uncertain' },
    { name: 'Uneasy' },
    { name: 'Unnerved' },
    { name: 'Unsettled' },
    { name: 'Unsure' },
  ],

  'Lonely/Empty': [
    { name: 'Abandoned' },
    { name: 'Alienated' },
    { name: 'Alone' },
    { name: 'Apathetic' },
    { name: 'Bored' },
    { name: 'Cutoff' },
    { name: 'Emotionless' },
    { name: 'Empty' },
    { name: 'Flat' },
    { name: 'Helpless' },
    { name: 'Hopeless' },
    { name: 'Indifferent' },
    { name: 'Invisible' },
    { name: 'Isolated' },
    { name: 'Lazy' },
    { name: 'Left out' },
    { name: 'Lonely' },
    { name: 'Meh' },
    { name: 'Neglected' },
    { name: 'Nothing' },
    { name: 'Numb' },
    { name: 'Powerless' },
    { name: 'Rejected' },
    { name: 'Resignation' },
    { name: 'Submissive' },
    { name: 'Tired' },
    { name: 'Undermined' },
    { name: 'Unemotional' },
    { name: 'Uninterested' },
  ],

  'Jealous/Disgust': [
    { name: 'Aversion' },
    { name: 'Contempt' },
    { name: 'Discontent' },
    { name: 'Disgust' },
    { name: 'Disgusted' },
    { name: 'Dislike' },
    { name: 'Displeasure' },
    { name: 'Envious' },
    { name: 'Greedy' },
    { name: 'Jealous' },
    { name: 'Loathing' },
    { name: 'Offended' },
    { name: 'Possessive' },
    { name: 'Resentful' },
    { name: 'Revulsion' },
  ],

  'Fear/Anxiety': [
    { name: 'Afraid' },
    { name: 'Agitated' },
    { name: 'Alarmed' },
    { name: 'Anxious' },
    { name: 'Apprehensive' },
    { name: 'Defensive' },
    { name: 'Despair' },
    { name: 'Discomfort' },
    { name: 'Distressed' },
    { name: 'Dread' },
    { name: 'Fearful' },
    { name: 'Frightened' },
    { name: 'Intimidated' },
    { name: 'Jittery' },
    { name: 'Jumpy' },
    { name: 'Nervous' },
    { name: 'Panic' },
    { name: 'Paranoid' },
    { name: 'Rattled' },
    { name: 'Scared' },
    { name: 'Stressed' },
    { name: 'Suspicious' },
    { name: 'Tense' },
    { name: 'Terrified' },
    { name: 'Troubled' },
    { name: 'Uneasy' },
    { name: 'Vigilant' },
    { name: 'Vulnerable' },
    { name: 'Weak' },
    { name: 'Worried' },
  ],

  'Worthless/Trapped': [
    { name: 'Awful' },
    { name: 'Bad' },
    { name: 'Broken' },
    { name: 'Claustrophobic' },
    { name: 'Crippled' },
    { name: 'Deficient' },
    { name: 'Demoralized' },
    { name: 'Emasculated' },
    { name: 'Exasperated' },
    { name: 'Excluded' },
    { name: 'Finished' },
    { name: 'Horrible' },
    { name: 'Impatient' },
    { name: 'Incapable' },
    { name: 'Incompetent' },
    { name: 'Ineffective' },
    { name: 'Inferior' },
    { name: 'Insecure' },
    { name: 'Insignificant' },
    { name: 'No good' },
    { name: 'Paralyzed' },
    { name: 'Persecuted' },
    { name: 'Powerless' },
    { name: 'Self-conscious' },
    { name: 'Self-critical' },
    { name: 'Self-loathing' },
    { name: 'Self-pity' },
    { name: 'Small' },
    { name: 'Stuck' },
    { name: 'Suffering' },
    { name: 'Suffocated' },
    { name: 'Trapped' },
    { name: 'Unimportant' },
    { name: 'Unlovable' },
    { name: 'Useless' },
    { name: 'Washed up' },
    { name: 'Worthless' },
  ],

  'Sad/Hurt': [
    { name: 'Belittled' },
    { name: 'Blue' },
    { name: 'Cheapened' },
    { name: 'Criticised' },
    { name: 'Crushed' },
    { name: 'Degraded' },
    { name: 'Depressed' },
    { name: 'Destroyed' },
    { name: 'Devastated' },
    { name: 'Disappointed' },
    { name: 'Discarded' },
    { name: 'Disgraced' },
    { name: 'Disheartened' },
    { name: 'Dismal' },
    { name: 'Down' },
    { name: 'Exploited' },
    { name: 'Grief' },
    { name: 'Heartbroken' },
    { name: 'Humiliated' },
    { name: 'Let down' },
    { name: 'Low' },
    { name: 'Melancholy' },
    { name: 'Miserable' },
    { name: 'Mocked' },
    { name: 'Neglected' },
    { name: 'Put down' },
    { name: 'Rejected' },
    { name: 'Ridiculed' },
    { name: 'Ruined' },
    { name: 'Sad' },
    { name: 'Taken for granted' },
    { name: 'Tortured' },
    { name: 'Unhappy' },
    { name: 'Upset' },
    { name: 'Used' },
  ],

  'Shame/Guilt': [
    { name: 'Ashamed' },
    { name: 'At fault' },
    { name: 'Disgraced' },
    { name: 'Embarrassed' },
    { name: 'Guilty' },
    { name: 'Negative' },
    { name: 'Pity' },
    { name: 'Regret' },
    { name: 'Remorseful' },
    { name: 'Responsible' },
    { name: 'Shame' },
  ],

  },

};