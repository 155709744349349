<template>
  <HeaderComponent :pageTitle="'Feelings Finder'" />
  <main
    class="min-h-screen overflow-x-hidden px-4 pt-24 sm:pt-48 pb-24 md:pb-8"
    data-aos="slide-left"
    data-aos-once="true"
    data-aos-duration="250"
  >
    <!-- Back Button (Chevron Icon Only) -->
    <div v-if="currentStep > 0" class="flex justify-start mb-2">
      <button
        @click="goBack"
        :disabled="currentStep === 0"
        class="p-0.5 stroke-2 border bg-white text-gray-700 flex rounded-full items-center gap-2 hover:text-gray-800"
        data-aos="fade-down"
        data-aos-duration="300"
      >
        <ChevronLeftIcon class="h-6 w-6" />
      </button>
    </div>

    <!-- Current Step -->
    <div
      class="border w-full rounded-xl shadow-sm max-w-md px-4 py-8 bg-white"
      data-aos="fade-up"
      data-aos-duration="400"
    >
      <!-- Step 0: Welcome Screen -->
      <div v-if="currentStep === 0" class="intro" data-aos="fade-in" data-aos-duration="600">
        <h1 class="px-6 leading-8 text-center text-primary font-semibold text-2xl">
          Welcome to the Feelings Finder
        </h1>
        <br />
        <p class="px-2 text-gray-600 font-normal text-base">
          This tool helps you identify your feelings in the moment, which is the first step toward managing them effectively.
          <br /><br />
          Often, our emotional vocabulary is limited, and by regularly checking in with how you’re feeling, you’ll become more in touch with your emotions.
          <br /><br />
          It’s not about tracking, just acknowledging your feelings and moving forward with your day.
        </p>
        <br />
        <div class="text-center mx-auto pt-2 pb-6">
          <button
            class="font-semibold text-white btn-ff-primary rounded-md py-3 w-full"
            @click="goForward"
            data-aos="zoom-in"
            data-aos-duration="300"
          >
            Let’s Begin
          </button>
        </div>
      </div>

      
<!-- Step 1: Feeling Type -->
<template v-if="currentStep === 1">
  <h2
    class="text-xl text-gray-700 font-light text-center pt-4 pb-4"
    data-aos="fade-up"
    data-aos-duration="300"
  >
    Firstly, is the feeling...
  </h2>
  <div 
    class="flex flex-wrap gap-4 justify-center"
    data-aos="fade-up"
    data-aos-delay="100"
    data-aos-duration="500"
  >
    <button
      v-for="(type, index) in Object.keys(feelingsData)"
      :key="index"
      class="font-semibold text-gray-700 bg-primary-300 border py-4 px-6 max-w-[200px]"
      @click="selectFeelingType(type)"
      data-aos="fade-right"
      :data-aos-delay="200 + index * 100"
    >
      {{ type }}
    </button>
  </div>
</template>

      <!-- Step 2: Feeling Category -->
      <template v-if="currentStep === 2">
        <h2
          class="text-xl text-gray-700 font-light text-center mb-4"
          data-aos="fade-up"
          data-aos-duration="300"
        >
        Now choose a category...
        </h2>
        <div class="grid grid-cols-1 gap-2" data-aos="fade-in" data-aos-duration="400">
          <button
            v-for="category in Object.keys(feelingsData[selectedFeelingType] || {})"
            :key="category"
            class="bg-gradient-to-r from-secondary-200 to-secondary-100 w-full h-12  border font-semibold text-base text-gray-800 text-center flex items-center justify-center hover:bg-blue-600"
            @click="selectCategory(category)"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            {{ category }}
          </button>
        </div>
      </template>

      <!-- Step 3: Specific Feelings -->
      <template v-if="currentStep === 3">
        <h2
          class="text-xl text-gray-700 font-light text-center mb-4"
          data-aos="fade-in"
          data-aos-duration="300"
        >
        Pick the feeling that resonates with you...
        </h2>
        <div class="grid grid-cols-1 gap-2">
          <button
            v-for="feeling in feelingsByCategory"
            :key="feeling.name"
            class="w-full h-12 bg-gradient-to-r from-primary-200 to-secondary-100 border flex items-center justify-center hover:bg-blue-600"
            @click="selectSpecificFeeling(feeling.name)"
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            <p class="text-gray-800 text-center">
              I feel <span class="text-gray-800 lowercase font-bold">{{ feeling.name }}</span>
            </p>
          </button>
        </div>
      </template>
<!-- Step 4: -->
<template v-if="currentStep === 4">
  <h2
    class="text-xl font-light text-center mb-4"
    data-aos="fade-in"
    data-aos-duration="300"
  >
    I'm feeling
    <span class="font-bold text-gray-800">
      {{ feelings.specific ? feelings.specific.toLowerCase() : '...' }}
    </span>
    because...
  </h2>
  
  <textarea
    v-model="feelings.reflection"
    class="w-full p-4 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
    rows="6"
    placeholder="Start typing..."
    data-aos="zoom-in"
    data-aos-duration="500"
  ></textarea>
  
  <div class="flex justify-between mt-4 gap-4">
    <button class="btn-ff-primary w-1/2" @click="completeCheckIn">Skip</button>
    <button class="btn-ff-primary w-1/2" @click="completeCheckIn">Finish</button>
  </div>
</template>

<!-- Step 5: Add Another Feeling -->
<template v-if="currentStep === 5">
  <div key="step-5" data-aos="fade-in" data-aos-duration="300">
    <h2 class="text-xl text-gray-700 font-light text-center mb-8">
      Would you like to add another feeling?
    </h2>
    <div class="flex justify-between" data-aos="zoom-in" data-aos-delay="150">
      <button class="btn-ff-primary w-24" @click="addAnotherFeeling">Yes</button>
      <button class="btn-ff-primary w-24" @click="finalComplete">No</button>
    </div>
  </div>
</template>

<!-- Step 6: Summary -->
<template v-if="currentStep === 6">
  <div key="step-6" data-aos="fade-in" data-aos-duration="300">
    <h2 class="text-xl font-semibold text-center mb-4">
      Your Feelings Summary
    </h2>
    <ul class="list-none">
      <li
        v-for="(feeling, index) in feelingsList"
        :key="index"
        class="mb-4"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <div class="p-4 border rounded bg-gray-50">
          <h3 class="text-lg font-light text-gray-700">
            I feel
            <span class="font-bold text-gray-800">
              {{ feeling.specific }}
            </span>
          </h3>
          <p class="text-gray-800 text-base mt-2">
            This is because {{ feeling.reflection || '(no reflection added)' }}
          </p>
        </div>
      </li>
    </ul>
    <button
      class="btn-ff-primary w-full mt-4"
      @click="resetProcess"
      data-aos="fade-in"
      data-aos-duration="300"
    >
      Finish
    </button>
  </div>
</template>

    </div>
  </main>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { feelingsData } from '../data/feelingsData';
import HeaderComponent from '../components/HeaderComponent';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';

// Reactive state
const currentStep = ref(0); // Start with the introduction
const selectedFeelingType = ref(null);
const selectedCategory = ref(null);
const feelingsList = ref([]); // Store all feelings
const feelings = ref({
  primary: null,
  category: null,
  specific: null,
  reflection: null,
});

// Computed property to filter feelings by selected type and category
const feelingsByCategory = computed(() => {
  return feelingsData[selectedFeelingType.value]?.[selectedCategory.value] || [];
});

// Initialize on page load
onMounted(() => {
  currentStep.value = 0; // Show introduction
});

// Step control
const goBack = () => {
  currentStep.value = Math.max(0, currentStep.value - 1);
};
const goForward = () => {
  if (currentStep.value === 0) {
    currentStep.value = 1; // Move to Feeling Type selection
  } else if (currentStep.value < 5) {
    currentStep.value++;
  } else if (currentStep.value === 5) {
    currentStep.value = 6; // Move to Summary
  }
};

// Handlers for selections
const selectFeelingType = (type) => {
  selectedFeelingType.value = type;
  feelings.value.primary = type;
  currentStep.value = 2;
};
const selectCategory = (category) => {
  selectedCategory.value = category;
  feelings.value.category = category;
  currentStep.value = 3;
};
const selectSpecificFeeling = (name) => {
  feelings.value.specific = name;
  currentStep.value = 4;
};

// Helper to check if the current feeling is already added
const isFeelingDuplicate = (feeling) => {
  return feelingsList.value.some(
    (item) =>
      item.primary === feeling.primary &&
      item.category === feeling.category &&
      item.specific === feeling.specific
  );
};

// Add another feeling
const addAnotherFeeling = () => {
  if (
    feelings.value.primary &&
    feelings.value.specific &&
    !isFeelingDuplicate(feelings.value)
  ) {
    feelingsList.value.push({ ...feelings.value });
  }
  currentStep.value = 1; // Reset to Feeling Type selection
  selectedFeelingType.value = null;
  selectedCategory.value = null;
  feelings.value = {
    primary: null,
    category: null,
    specific: null,
    reflection: null,
  };
};

// Completion
const completeCheckIn = () => {
  if (
    feelings.value.primary &&
    feelings.value.specific &&
    !isFeelingDuplicate(feelings.value)
  ) {
    feelingsList.value.push({ ...feelings.value });
  }
  currentStep.value = 5; // Move to Add Another Feeling
};
const finalComplete = () => {
  currentStep.value = 6; // Move to Summary
};

// Reset the process
const resetProcess = () => {
  currentStep.value = 0; // Return to introduction
  selectedFeelingType.value = null;
  selectedCategory.value = null;
  feelingsList.value = [];
  feelings.value = {
    primary: null,
    category: null,
    specific: null,
    reflection: null,
  };
};

// Can go forward based on current step
const canGoForward = computed(() => {
  if (currentStep.value === 0) return true; // Always allow moving forward from intro
  if (currentStep.value === 1) return !!selectedFeelingType.value;
  if (currentStep.value === 2) return !!selectedCategory.value;
  if (currentStep.value === 3) return !!feelings.value.specific;
  if (currentStep.value === 4) return true; // Can always move to Step 5
  return false;
});
</script>

